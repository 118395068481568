import axios from '../api/axios';
import useAuth from './useAuth';


/**
 * Custom hook to refresh the authentication token.
 *
 * @function useRefreshToken
 * @returns {Function} A function that refreshes the authentication token.
 *
 * @example
 * const refresh = useRefreshToken();
 * refresh().then(newAccessToken => {
 *     console.log('New access token:', newAccessToken);
 * });
 *
 * @async
 * @function
 * @name refresh
 * @returns {Promise<string>} A promise that resolves to the new access token.
 */
const useRefreshToken = () => {
    const { auth, setAuth } = useAuth();
    
    const refresh = async () => {
        const response = await axios.post(
            'api/token/refresh/', 
            {"refresh": auth.refreshToken},
        );
        setAuth(prev => {
            prev.accessToken = response.data.access;
            return prev;
        });
        
        return response.data.access;
    }
    return refresh;
};

export default useRefreshToken;