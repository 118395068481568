import {useState, useEffect } from 'react';
import { useSelector } from 'react-redux'
import ActivityCard from "../commons/ActivityCard";
import { useMemo } from 'react';
import { selectAllAvailableExperiments } from '../redux/availableExperimentsSlice';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { ButtonGroup, ToggleButton } from 'react-bootstrap';
import { Button } from 'react-bootstrap';


function ExperimentList(){
    
    let exp = useSelector(selectAllAvailableExperiments) || [];

    let [filterSelection, setFilterSelection]= useState(exp)
    let [modeSelected, setModeSelected] = useState()

    let [requisiteSelected, setRequisiteSelected] = useState([]);
    let [deviceSelected, setDeviceSelected] = useState([]);
    const isMobile = window.innerWidth < 992;

    

    const areFiltersApplied = modeSelected || requisiteSelected || deviceSelected;

    const isDebug = true; // Cambiar a true para habilitar logs

    if (isDebug) {
        console.log('REQUISITE:', requisiteSelected);
        console.log('DEVICE:', deviceSelected);
        console.log('MODE:', modeSelected);
        console.log('FILTER SELECTION:', filterSelection);
    }

    const filteredExperiments = useMemo(() => {
        let filtered = exp || [];
    
        if (modeSelected) {
            filtered = modeSelected === 'online'
                ? filtered.filter(e => !e.is_scheduled)
                : filtered.filter(e => e.is_scheduled);
        }
    
        if (requisiteSelected) {
            filtered = filtered.filter(e => e.requisites.includes(requisiteSelected));
        }
    
        if (deviceSelected) {
            filtered = filtered.filter(e => e.device_restriction === deviceSelected);
        }
    
        return filtered;
    }, [exp, modeSelected, requisiteSelected, deviceSelected]);
    
    useEffect(() => {
        setFilterSelection(filteredExperiments);
    }, [filteredExperiments]);
   

    const resetFilters = () => {
        setModeSelected(null);
        setRequisiteSelected([]);
        setDeviceSelected([]);
        setFilterSelection(exp); // Restaurar la lista completa
    };
    

    useEffect(() => {
        applyFilters(requisiteSelected, deviceSelected, modeSelected);
    }, [requisiteSelected, deviceSelected, modeSelected, exp]);
    
    
    let filters =[ 
        { name : 'Online', value: 'online', btn: 'btn-red', outln: 'outline-red'},
        { name : 'Con turno', value: 'con turno', btn: 'btn-green', outln: 'outline-green'}
        
    ]

    let  DEVICE_CHOICES=[ 
        { name : 'Escritorio', value: 'E'},
        { name : 'Celular', value: 'C'}
    ]

    let REQUISITES_CHOICES =[ 
        { name : 'Auriculares', value: 'A'},
        { name : 'Microfono', value: 'M'},
        { name : 'Webcam', value: 'W'},
        { name : 'Ambiente silencioso', value: 'B'},
        { name : 'Teclado', value: 'O'},
    ]


    const handleModeFilter = (value) => {
        setModeSelected(value);
        applyFilters(requisiteSelected, deviceSelected, value);
    };
    
    
    const handleRequisiteFilter = (value) => {
        setRequisiteSelected((prev) =>
            prev.includes(value) ? prev.filter((item) => item !== value) : [...prev, value]
        );
        applyFilters([...requisiteSelected, value], deviceSelected, modeSelected);
    };
    
    const handleDeviceFilter = (value) => {
        setDeviceSelected((prev) =>
            prev.includes(value) ? prev.filter((item) => item !== value) : [...prev, value]
        );
        applyFilters(requisiteSelected, [...deviceSelected, value], modeSelected);
    };
    
    
    
    const applyFilters = (reqValues, deviceValues, modeValue) => {
        let filteredExp = exp || [];
    
        if (modeValue) {
            filteredExp = modeValue === 'online' 
                ? filteredExp.filter(e => !e.is_scheduled) 
                : modeValue === 'con turno'
                ? filteredExp.filter(e => e.is_scheduled)
                : filteredExp;
        }
    
        if (reqValues.length > 0) {
            filteredExp = filteredExp.filter(e => 
                reqValues.every(req => e.requisites.includes(req))
            );
        }
    
        if (deviceValues.length > 0) {
            filteredExp = filteredExp.filter(e => deviceValues.includes(e.device_restriction));
        }
    
        setFilterSelection(filteredExp);
    };

    return(
        <Row className={'bg-neutral mt-3 px-4 100-vw'}> 
            <p className={'noto-sans super-subtitles-size text-secundario fw-bolder p-3'}> Experimentos </p>
            <Row className={'mb-3 d-flex justify-content-start'}>
            <p className="m-2">
                {modeSelected || requisiteSelected.length > 0 || deviceSelected.length > 0 ? (
                    <small className="text-muted">Filtros aplicados:</small>
                ) : null}

                {modeSelected && (
                    <span>
                        <small className=" mx-2 text-secundario">| Modo:</small> {modeSelected}
                    </span>
                )}

                {requisiteSelected.length > 0 && (
                    <span>
                        <small className=" mx-2 text-secundario">| Requisitos:</small>
                        {requisiteSelected
                            .map((req) => REQUISITES_CHOICES.find((choice) => choice.value === req)?.name || 'Desconocido')
                            .join(', ')}
                    </span>
                )}

                {deviceSelected.length > 0 && (
                    <span>
                        <small className=" mx-2 text-secundario">| Dispositivos:</small>
                        {deviceSelected
                            .map((device) => DEVICE_CHOICES.find((choice) => choice.value === device)?.name || 'Desconocido')
                            .join(', ')}
                    </span>
                )}
            </p>
                <Col>
                <ButtonGroup className={'m-2'}>
                    {filters.map((filter, idx) => (
                        <ToggleButton
                            key={idx}
                            id={`filter-${idx}`}
                            type="radio"
                            variant={modeSelected === filter.value ? 'primary' : 'outline-secondary'}
                            className={modeSelected === filter.value ? filter.btn : 'outline-secondary'}
                            name="mode_filter"
                            value={filter.value}
                            checked={modeSelected === filter.value}
                            onChange={(e) => handleModeFilter(e.currentTarget.value)}
                        >
                            {filter.name}
                        </ToggleButton>
                    ))}
                </ButtonGroup>
                <ButtonGroup className={'m-2'} vertical={ isMobile ? true : false}>
                    {REQUISITES_CHOICES.map((req, idx) => (
                        <ToggleButton
                            key={idx}
                            id={`requisite-${idx}`}
                            type="checkbox"
                            variant={requisiteSelected.includes(req.value) ? 'primary' : 'outline-secondary'}
                            name="requisite_filter"
                            value={req.value}
                            checked={requisiteSelected.includes(req.value)}
                            onChange={() => handleRequisiteFilter(req.value)}
                        >
                            {req.name}
                        </ToggleButton>
                    ))}
                </ButtonGroup>
                <ButtonGroup className={'m-2'}>
                    {DEVICE_CHOICES.map((device, idx) => (
                        <ToggleButton
                            key={idx}
                            id={`device-${idx}`}
                            type="checkbox"
                            variant={deviceSelected.includes(device.value) ? 'primary' : 'outline-secondary'}
                            name="device_filter"
                            value={device.value}
                            checked={deviceSelected.includes(device.value)}
                            onChange={() => handleDeviceFilter(device.value)}
                        >
                            {device.name}
                        </ToggleButton>
                    ))}
                </ButtonGroup>

                <Button 
                    className="m-2" 
                    variant="secondary" 
                    onClick={resetFilters} 
                    disabled={!areFiltersApplied}
                >
                    Quitar Filtros
                </Button>
                
                </Col>
                </Row>
                {/* Mostrar mensaje si no hay resultados */}
                {filterSelection.length === 0 ? (
                    <p className="text-center text-danger">No hay resultados para los filtros aplicados.</p>
                ) : (
                    <Row>   
                        {filterSelection.map((a) => (   
                            <Col sm={12} md={4} lg={3} className={'d-flex align-items-stretch mb-3'}>                       
                                <ActivityCard 
                                    type={a.is_scheduled ? 'Con Turno' : 'Online'} 
                                    id={a.id}                        
                                    image={a.image}
                                    title={a.name}
                                    estimated_time={'x minutos'}
                                    text={a.description}
                                    is_scheduled={a.is_scheduled}
                                    date_created={a.creation_datetime}
                                    owner={a.owner}
                                    url={a.url}
                                    consent_file={a.consent_file}
                                    restrictions={a.device_restriction}
                                    requisites={a.requisites}
                                /> 
                            </Col>              
                        ))}
                      
                    </Row>
                )}
        </Row>
    )
} 

export default ExperimentList;
