import { addAvailableExperiment } from "../redux/availableExperimentsSlice";

import { addExperiment } from "../redux/experimentsSlice";
import { addSubject } from "../redux/subjectSlice";
import { addSessions } from "../redux/sessionsSlice";
import { addMessage } from "../redux/messageSlice";
import { addMe } from "../redux/meSlice";

// Función para obtener experimentos
export const getExperiments = async (controller, dispatch, axiosPrivate, auth) => {
    try {
        const response = await axiosPrivate.get('api/experiments/available/', {
            signal: controller.signal,
            headers: {
                Authorization: `Bearer ${auth.accessToken}`,
            },
        });
        dispatch(addExperiment(response.data));
    } catch (err) {
        console.error(err);
    }
};

// Función para obtener experimentos disponibles sin pendientes
export const getAvailableExperiments = async (controller, dispatch, axiosPrivate, auth) => {
    try {
        const response = await axiosPrivate.get('api/experiments/available_without_pending/', {
            signal: controller.signal,
            headers: {
                Authorization: `Bearer ${auth.accessToken}`,
            },
        });
        dispatch(addAvailableExperiment(response.data));
    } catch (err) {
        console.error("Error in getAvailableExperiments:", err);
    }
};

// Función para obtener sesiones
export const getSessions = async (controller, dispatch, axiosPrivate, auth) => {
    try {
        const response = await axiosPrivate.get('api/subjects/sessions/', {
            signal: controller.signal,
            headers: {
                Authorization: `Bearer ${auth.accessToken}`,
            },
        });
        dispatch(addSessions(response.data));
     
    } catch (err) {
        console.error(err);
    }
};

// Función para obtener sujetos
export const getSubject = async (controller, dispatch, axiosPrivate, auth) => {
    try {
        const response = await axiosPrivate.get('api/subjects/', {
            signal: controller.signal,
            headers: {
                Authorization: `Bearer ${auth.accessToken}`,
            },
        });
        dispatch(addSubject(response.data));
    } catch (err) {
        console.error(err);
    }
};

// Función para obtener mensajes
export const getMessage = async (controller, dispatch, axiosPrivate, auth) => {
    try {
        const response = await axiosPrivate.get('api/greeting/', {
            signal: controller.signal,
            headers: {
                Authorization: `Bearer ${auth.accessToken}`,
            },
        });
        dispatch(addMessage(response.data));
    } catch (err) {
        console.error(err);
    }
};

// Función para obtener me data
export const getMe = async (controller, dispatch, axiosPrivate, auth) => {
    try {
        const response = await axiosPrivate.get('api/users/me/', {
            signal: controller.signal,
            headers: {
                Authorization: `Bearer ${auth.accessToken}`,
            },
        });
        dispatch(addMe(response.data));
    } catch (err) {
        console.error(err);
    }
};
