import axios from 'axios';


export const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export default axios.create({
    baseURL:  BASE_URL
});

export const axiosPrivate = axios.create({
    baseURL: BASE_URL,
    headers: { 'Content-Type': 'application/json' },
    withCredentials: true
});


axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';


// Response interceptor to handle token refresh
/*
axiosPrivate.interceptors.response.use(
    function (response) {
        return response;
    },
    async function (error) {
        const originalRequest = error.config;

        // If access token expired, try refreshing it
        if (error.response?.status === 403 && !originalRequest._retry) {
            originalRequest._retry = true;
            try {
                const refreshToken = JSON.parse(localStorage.auth).refreshToken;
                const response = await axios.post(BASE_URL + "api/token/refresh/", {
                    refresh: refreshToken,
                });

                if (response.status === 200) {

                    // TODO: Reactify this.
                    let auth = JSON.parse(localStorage.auth);
                    auth.accessToken = response.data.access;
                    localStorage.setItem("auth", JSON.stringify(auth));

                    originalRequest.headers.Authorization = `Bearer ${auth.accessToken}`;
                    return axiosPrivate(originalRequest); // Retry the original request
                }
            } catch (refreshError) {
                console.error("Refresh token invalid or expired", refreshError);
                localStorage.removeItem("auth");
                window.location.href = "/login"; // Redirect to login page
            }
        }
        return Promise.reject(error);
    }
);
*/
